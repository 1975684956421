import React, {Component} from 'react';
import classNames from "classnames";
import ShareButton from "./ShareButton";

class Soundbite extends Component {
    constructor(props) {
        super(props);
        this.sbRef = React.createRef();
        this.audioRef = React.createRef();

    }

    state = {
        'progress': 0,
        'soundBiteWidth': null
    }

    playAudio = () => {
        let newAudio = document.getElementById('audio-' + this.props.bite.text)
        newAudio.play()


        newAudio.addEventListener('timeupdate', () => {
            const currentPercentage = newAudio.currentTime / newAudio.duration

            const progressPxWidth = this.state.soundBiteWidth * currentPercentage

            this.setState({
                'progress': progressPxWidth
            })
        })

        newAudio.addEventListener('ended', () => {
            setTimeout(() => {
                this.setState({
                    'progress': 0
                })
            }, 600)

        })
    }

    componentDidMount() {
        this.setState({
            soundBiteWidth: this.sbRef.current.clientWidth
        })
    }


    render() {
        let style = {
            'clip': `rect(0px, ${this.state.progress}px, 10px, 0px)`
        };

        return (
            <div className="bubble-wrap mb-8 flex items-center">
                <div className={classNames('bubble', { single: this.props.single })} ref={this.sbRef} onClick={() => {
                    this.playAudio()
                }}>

                    <span className="font-serif ">{this.props.bite.text}
                    </span>
                    <audio width="320" height="240" id={"audio-" + this.props.bite.text} className="audio-player">
                        <source src={this.props.bite.file} type="audio/mp3" />
                        🔇
                    </audio>
                    <div className="bubble-arrow">
                        <svg className="bubble-arrow-svg">
                            <svg id="bubble-arrow" viewBox="0 0 32 21" preserveAspectRatio="xMinYMin meet" width="30px"
                                 height="30px">
                                <path d="M0 0h32L13.657 18.343A8 8 0 0 1 0 12.686V0z" fill="currentColor"
                                      fillRule="evenodd"></path>
                            </svg>
                        </svg>
                    </div>

                    <div className="bubble-progress" style={style}>
                    </div>

                </div>
                {this.props.setShowSharePopup && (
                    <ShareButton setShowSharePopup={this.props.setShowSharePopup} sound={this.props.index} setSoundToShare={this.props.setSoundToShare}></ShareButton>
                ) }

            </div>
        );
    }
}

export default Soundbite;