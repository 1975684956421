import React, {useRef, useState} from 'react';
//@ts-ignore
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Confetti from 'react-confetti'
import ConfettiExplosion from 'react-confetti-explosion';

function CreateUniqueLinkPopup({setShowSharePopup, sound}: any) {

    const [text, setText] = useState('');
    const [furText, setFurText] = useState('');
    const [vonText, setVonText] = useState('');
    const inputRef = useRef(null);

    const [isExploding, setIsExploding] = React.useState(false);


    const url = `https://geslachtdepauw.vandevliet.me/?sound=${sound}&from=${vonText}&to=${furText}`;



    const handlePopupClick = (e: any) => {
        e.stopPropagation();
    };

    return (
        <div className="fixed left-0 top-0 w-full h-full
         backdrop-blur bg-black/70 z-20"
             onClick={(e) => {
            setShowSharePopup(false);
        }}>
            <div className="static w-full h-screen top-0 left-0">
                <div className="relative flex flex-1 w-full h-full justify-center items-center ">
                    <div className="popup  lg:rounded-2xl rounded-none lg:px-[5rem] px-0" onClick={handlePopupClick}>
                        <div className="bg-white rounded-2xl p-2 lg:p-8 ">

                            <div className="flex flex-1 w-full justify-end">
                                <button onClick={() => setShowSharePopup(false)}
                                        className="text-center p-2 w-12 rounded-full h-12 border-4 border-gray-200 font-extrabold text-gray-500">X
                                </button>
                            </div>
                            <h1 className="font-serif text-center font-bold text-4xl"> Delen!</h1>
                            <p className="text-center mt-4 font-serif">Deel deze audiofragmente mit iemand!</p>

                            <div className="flex flex-1 justify-center mt-20 flex-col">
                                <div className="flex flex-1 flex-col lg:flex-row">
                                    <div className=" m-8">
                                        <label htmlFor="forTxt" className="font-serif font-extrabold text-2xl">
                                            Fur...
                                            <input
                                                ref={inputRef}
                                                id="forTxt"
                                                type="text"
                                                value={furText}
                                                onChange={(e) => setFurText(e.target.value)}
                                                className="w-full h-16 px-4 text-xl border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                                placeholder="Fur..."
                                            />
                                        </label>
                                    </div>
                                    <div className=" m-8">
                                        <label htmlFor="vonText" className="font-serif font-extrabold text-2xl">
                                            Von...
                                            <input
                                                ref={inputRef}
                                                id="vonText"
                                                type="text"
                                                value={vonText}
                                                onChange={(e) => setVonText(e.target.value)}
                                                className="w-full h-16 px-4 text-xl border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                                placeholder="Von..."
                                            />
                                        </label>
                                    </div>

                                </div>

                                <div className=" flex flex-1 flex-col">
                                    <div className="relative mb-4  m-8">
                                        <label htmlFor="personalurl" className="font-serif font-extrabold text-2xl">
                                            Je persönlichen link
                                            <input
                                                ref={inputRef}
                                                type="text"
                                                id="personalurl"
                                                value={url}
                                                onChange={(e) => setText(e.target.value)}
                                                className="w-full h-16 px-4 text-xl border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                                placeholder="Enter your text here..."
                                            />
                                        </label>
                                        <div className="mt-4">

                                            <CopyToClipboard text={url} onCopy={() => {
                                                setIsExploding(true);
                                                setTimeout(() => {
                                                    setIsExploding(false)
                                                }, 2000)
                                            }}>
                                                <button
                                                    className="font-serif text-3xl w-full bg-red-950 hover:bg-red-900 active:bg-black text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"
                                                >
                                                    Die link kopfiëren
                                                </button>
                                            </CopyToClipboard>


                                        </div>
                                        <div className="flex flex-col justify-center items-center pointer-events-none mt-[-10rem] bg-red-400 absolute m-auto left-0 right-0">
                                            {isExploding && <ConfettiExplosion colors={['#dd0f0f', '#fff']} height="100vh" />}
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default CreateUniqueLinkPopup;