import React, {Component, useState} from 'react';
import './App.scss';
import jelleLogo from './img/jelle.png'

import Soundbite from "./components/Soundbite";
import SingleSoundbitePopup from "./components/SingleSoundbitePopup";
import soundbite from "./components/Soundbite";
import CreateUniqueLinkPopup from "./components/CreateUniqueLinkPopup";


const soundBites = [
  {
    "text": "Allee, anschluss! ⛷️",
    "file": "https://cdn.prijzenvolger.com/jelle/audio/geslacht-de-pauw-allee-anschluss.mp3"
  },
  {
    "text": "Das ist.. sehr schnell",
    "file": "https://cdn.prijzenvolger.com/jelle/audio/geslacht-de-pauw-das-ist-sehr-schnell.mp3"
  },

  {
    "text": "Mit folgen, mit folgen",
    "file": "https://cdn.prijzenvolger.com/jelle/audio/geslacht-de-pauw-mit-folgen-he-mit-folgen.mp3"
  },
  {
    "text": "Das unser Spönsor hier",
    "file": "https://cdn.prijzenvolger.com/jelle/audio/geslacht-de-pauw-das-unse-sponsor-hier.mp3"
  },
  {
    "text": "Das finde ich Straf ze!",
    "file": "https://cdn.prijzenvolger.com/jelle/audio/geslacht-de-pauw-das-vind-ich-schtraff-ze.mp3"
  },
  {
    "text": "Eerst arbeiten und dan...",
    "file": "https://cdn.prijzenvolger.com/jelle/audio/geslacht-de-pauw-eerst-arbeiten-und-dan.mp3"
  },
  {
    "text": "Ge filmpt de achterkant van onse Kopfen",
    "file": "https://cdn.prijzenvolger.com/jelle/audio/geslacht-de-pauw-ge-filmpt-unse-kopfen.mp3"
  },
  {
    "text": "Haben sie keine opfuding genoßen?",
    "file": "https://cdn.prijzenvolger.com/jelle/audio/geslacht-de-pauw-haben-sie-keine-upvuding-genossen.mp3"
  },
  {
    "text": "Hela, was ist das!?",
    "file": "https://cdn.prijzenvolger.com/jelle/audio/geslacht-de-pauw-hela-was-ist-das.mp3"
  },
  {
    "text": "Das mach nicht he!",
    "file": "https://cdn.prijzenvolger.com/jelle/audio/geslacht-de-pauw-das-macht-nicht-he.mp3"
  },
  {
    "text": "Mit seine poßen in das Eßen",
    "file": "https://cdn.prijzenvolger.com/jelle/audio/geslacht-de-pauw-mit-seinen-possen-in-das-essen.mp3"
  },
  {
    "text": "Und.. Sehr belangreich!",
    "file": "https://cdn.prijzenvolger.com/jelle/audio/geslacht-de-pauw-und-sehr-belangreich.mp3"
  },
  {
    "text": "Unser Spönser heeft alles betähld",
    "file": "https://cdn.prijzenvolger.com/jelle/audio/geslacht-de-pauw-und-unse-sponsor-hat-alles-betald.mp3"
  },
  {
    "text": "Wat sitzen sie daar te doen",
    "file": "https://cdn.prijzenvolger.com/jelle/audio/geslacht-de-pauw-wass-sitzen-sie-dar-zu-tuhn.mp3"
  },
  {
    "text": "Wir kommen af he",
    "file": "https://cdn.prijzenvolger.com/jelle/audio/geslacht-de-pauw-wir-kommen-af-he.mp3"
  },
  {
    "text": "Zijde gij nicht beschämd?",
    "file": "https://cdn.prijzenvolger.com/jelle/audio/geslacht-de-pauw-zijde-gij-niet-beschamd.mp3"
  },
  {
    "text": "Godverdomse heten truffel gij!",
    "file": "https://cdn.prijzenvolger.com/jelle/audio/geslacht-de-pauw-godverdomse-hete-truffel-gij.mp3",
    "bonus": true
  },
]


const  App = () => {

  const sp = new URLSearchParams(window.location.search)
  const [from, setFrom] = useState(sp.get('from'))
  const [to, setTo] = useState(sp.get('to'))
  const [sound, setSound] = useState(sp.get('sound'))
  const [showSharePopup, setShowSharePopup] = useState(false)
  const [soundToShare, setSoundToShare] = useState(0)

  const closeWindow = () => {
    setFrom(null)
    setTo(null)
    setSound(null)
  }

    console.log('sound > ', sound)

    return (
        <div className="App bg-blend-darken backdrop-opacity-20 w-full">

          <div className="bg-black/70  p-8 ">

            {showSharePopup && <CreateUniqueLinkPopup setShowSharePopup={setShowSharePopup} sound={soundToShare} />}
            {sound &&  <SingleSoundbitePopup from={from} to={to} sound={soundBites[Number(sound)]} closeWindow={closeWindow}></SingleSoundbitePopup>}
            <div className="">
              <div className="max-w-[50rem]">

                <h1 className="text-5xl font-serif font-bold text-white">Geslacht De Pauw Soundboard <br/>(im
                  Deutsch)</h1>
                <br/>

                <p className="text-gray-200 font-serif">Viele jahren nadat die legendarische episoot von der Geslacht
                  De Pauw werd aus
                  gesunden op die VRT, es gibt eindlich eine geluidsborde mit der beste
                  zinnen von Bart De Pauw -- im Deutsch!</p> <br/>

                <div className="">
                  {soundBites.map((soundBite, index) => {
                    return (
                        <Soundbite bite={soundBite} file={soundbite} index={index}  key={soundBite.text} setShowSharePopup={setShowSharePopup} setSoundToShare={setSoundToShare}/>
                    )
                  })}
                </div>
              </div>
            </div>
            <footer className="w-full flex  justify-center">
              <div className="flex flex-col justify-center items-center">
                <p className="text-white font-serif">Wieder eine zeitprojekt von</p>
                <div className="pt-4">
                  <a href="https://vandevliet.me?ref=gdp" className="text-center">
                    <img src={jelleLogo} alt="Jelle Van de Vliet Logo" className="h-10"/>
                  </a></div>
              </div>
            </footer>
          </div>
        </div>
    );
}

export default App;
