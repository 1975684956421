import React from 'react';
import Soundbite from "./Soundbite";

function SingleSoundbitePopup({from, sound, to, closeWindow}: any) {
    const handlePopupClick = (e: any) => {
        e.stopPropagation();
    };

    const introText = () => {
        if(from && to) {
            return `${to}, ${from} hat einde geluidsfragmente für sie`
        }
        if(from && !to ) {
            return `Helaba muis, ${from} hat einde geluidsfragmente für sie`
        }
        if (!from && to) {
            return `Helaba ${to}, iemand hat einde geluidsfragmente für sie`
        }
        return "Iemand hat einde geluidsfragmente für sie"
    }
    return (
        <div className="fixed left-0 top-0 w-full h-full
         backdrop-blur bg-black/70 z-20" onClick={closeWindow}>
            <div className="static w-full h-screen top-0 left-0">
                <div className="relative flex flex-1 w-full h-full justify-center items-center">
                    <div className="popup max-w-[50rem] w-full lg:w-1/2  bg-white p-2 lg:p-8 xl:px-20 px-8 rounded-2xl" onClick={handlePopupClick}>
                        <div className="flex flex-1 w-full justify-end ">
                            <button onClick={closeWindow} className="text-center p-2 w-12 rounded-full h-12 border-4 border-gray-200 font-extrabold text-gray-500">X</button>
                        </div>
                        <h1 className="font-serif text-center font-bold text-4xl">
                            {introText()}</h1>
                        <p className="text-center">Klik auf die tekstbubbel zu hoor es.</p>

                        <div className="flex flex-1 justify-center mt-20">

                            <Soundbite bite={sound} single={true}></Soundbite>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default SingleSoundbitePopup;